import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("./welcome/component").then((m) => m.MyComponent),
  },
  {
    path: "we-will-contact-you",
    loadComponent: () =>
      import("./we-will-contact-you/component").then((m) => m.MyComponent),
  },
  {
    path: "confirm-your-email",
    loadComponent: () =>
      import("./confirm-your-email/component").then((m) => m.MyComponent),
  },
  {
    path: "owner",
    loadComponent: () => import("./owner/component").then((m) => m.MyComponent),
  },
  {
    path: "property",
    loadComponent: () =>
      import("./property/component").then((m) => m.MyComponent),
  },
  {
    path: "subscription",
    loadComponent: () =>
      import("./subscription/component").then((m) => m.MyComponent),
  },
  {
    path: "summary",
    loadComponent: () =>
      import("./summary/component").then((m) => m.MyComponent),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
