import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router, Event, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NavigationLogoService } from './service';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { MyComponent } from './items/component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgStyle, NgClass } from '@angular/common';

@Component({
    selector: 'app-navigation-top',
    templateUrl: './template.html',
    styleUrls: ['./styles.sass'],
    standalone: true,
    imports: [NgIf, NgStyle, FormsModule, NgClass, MyComponent,MyComponent, TranslateModule,],
    providers: [NavigationLogoService]
})
export class NavigationTopComponent implements OnInit {
  loading: boolean = true;
  menuVisible: boolean = false;
  marginTop: string = "0";
  languages: Array<any> = [];
  en: string = 'en';
  es: string = 'es';
  selectChangeLanguage: boolean = false;
  @Output() public language = new EventEmitter();
  changeLangSelect: boolean = false;
  size: number = 48;
  activateColor: any;
  constructor(
    private router: Router,
    public translate: TranslateService,
    private service: NavigationLogoService
  ) {

  }

  ngOnInit() {
    this.loading = true;
    this.router.events.subscribe((val: Event) => {
      if (val instanceof NavigationEnd) {
        if (val.url && val.url != '/') {
          this.size = 40
        } else {
          this.size = 48
        }
      }


      this.marginTop = "0";
    });

    this.service.subscribe(options => {
      this.marginTop = options + "%";
    });

    if (this.languages.length === 0) {
      this.service.listLanguages()
        .then(languages => {
          this.languages = languages;
          this.loading = false;
        })
    }
    let lang = JSON.parse(localStorage.getItem('state') || "{}")
    if (lang && lang.owner && lang.owner.language) {
      this.translate.use(lang.owner.language);
    }

  }

  changeLang(lang) {
    let state = JSON.parse(localStorage.getItem('state') || "{}");
    state = Object.assign({}, state, {
      owner: Object.assign({}, state.owner, {
        language: lang
      })
    })
    localStorage.setItem('state', JSON.stringify(state));
    this.translate.use(lang);
    this.changeLangSelect = true;
  }
  continue() {
    this.language.emit('')
    this.selectChangeLanguage = false;
    this.ngOnInit();
  }
  menuVisibleOutputMethod(e) {
    this.menuVisible = e;
  }
  selectLanguage() {
    this.language.emit('select')
    this.selectChangeLanguage = true;
  }
  active(e) {
    this.activateColor = e;

  }

}
