<div class="container">
    <div class="row margin-bottom">
        <div class="col s12 m12 l12  xl10 offset-xl1 center  padding-right0">
            <div *ngIf="menuVisible" class="myMenuTop">
                <a *ngFor="let it of items;let i = index "
                    [ngClass]="{'active': it.active,'border-green': it.active, 'disabled': !canGo(it.codename)}"
                    (click)="go(it.codename)" class="col s4 "><span
                        [ngClass]="{'activatetext': it.active}">{{ i+1 }}</span> {{ it.name | translate }} </a>

            </div>
        </div>
    </div>
</div>