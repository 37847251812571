/* src/app/navigation/top/items/styles.sass */
.myContainer {
  width: 70%;
  margin: 0 auto;
  text-align: left;
}
.myMenuTop {
  margin: 0;
  padding: 0;
  text-align: center;
}
.myMenuTop a {
  color: #FFF;
  opacity: 0.5;
  text-decoration: none;
  padding: 15px 25px;
  cursor: pointer;
  display: inline-block;
}
.myMenuTop a.disabled {
  cursor: not-allowed;
}
.myMenuTop a.active {
  opacity: 1;
  color: #F9F9F9;
  cursor: default;
}
/*# sourceMappingURL=styles.css.map */
