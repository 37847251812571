<div class="col s12 center" [ngStyle]="{'margin-top': marginTop}" *ngIf="!selectChangeLanguage">
    <img src="../../../assets/GH-dark-bg.svg" class="padding0 margin-top20" height="{{size}}" />
    <br>
    <br>
</div>

<div class="change-languages" *ngIf="!selectChangeLanguage">





    <select class="browser-default language" (change)="changeLang($event.target.value)"
        style="border-bottom: 0px !important;">
        <option value="en" [selected]="translate.currentLang =='en'">&nbsp;&nbsp;&nbsp;&nbsp;
            English
        </option>
        <option value="es" [selected]="translate.currentLang =='es'">&nbsp;&nbsp;&nbsp;&nbsp;
            Español
        </option>
    </select>




</div>
<div class="row" *ngIf="selectChangeLanguage">
    <div class="col s12  l4 m4 xl4 offset-l4 offset-m4 offset-xl4 margin-top15">
        <div [ngStyle]="{'margin-top': marginTop}">
            <img src="../../../assets/GH-dark-bg.svg" class="padding0 margin-top20" height="{{size}}" />
            <br>
            <div class="col l8 xl8 m8 s12 offset-l2 offset-xl2 offset-m2">
                <div class="card " *ngIf="!loading">
                    <div class="card-content black-text">

                        <div class="container-body center-align">
                            <span class="text24">{{'Please select your language' | translate }} </span>
                            <br><br>
                            <i class="material-icons float-traslate ">translate</i>

                            <select class="browser-default " (change)="changeLang($event.target.value)">
                                <option value="en" [selected]="translate.currentLang =='en'">&nbsp;&nbsp;&nbsp;&nbsp;
                                    English
                                </option>
                                <option value="es" [selected]="translate.currentLang =='es'">&nbsp;&nbsp;&nbsp;&nbsp;
                                    Español
                                </option>
                            </select>
                            <br>
                        </div>
                    </div>


                </div>
                <br>
                <a class="btn btn-large right" [ngClass]="{'active':changeLangSelect,'disabled':!changeLangSelect}"
                    (click)="continue()">
                    {{ 'Continue' | translate }}
                </a>
            </div>

        </div>
    </div>
</div>
<app-navigation-items (menuVisibleOutput)="menuVisibleOutputMethod($event)" *ngIf="!selectChangeLanguage">
</app-navigation-items>