import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { RouterOutlet } from '@angular/router';
import { NgIf } from '@angular/common';
import { NavigationTopComponent } from './navigation/top/component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    standalone: true,
    imports: [NavigationTopComponent, NgIf, RouterOutlet]
})
export class AppComponent {
  selectChangeLanguage: any;

  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    let state = JSON.parse(localStorage.getItem('state') || "{}");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (state && state.owner && state.owner.language) {
      translate.use(state.owner.language);
    } else {
      state = Object.assign({}, state, {
        owner: Object.assign({}, state.owner, {
          language: state.owner ? state.owner.language : window.navigator.language.substr(0, 2),
        })
      })
      localStorage.setItem('state', JSON.stringify(state));
      translate.use(state.owner.language);
    }
  }
  changeLanguage(e) {
    this.selectChangeLanguage = e;
  }

  onActivate(event) {
    window.scroll(0,0);
  }
}
