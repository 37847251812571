/* src/app/navigation/top/styles.sass */
img {
  padding: 20px 10px;
}
img .large-logo {
  width: 250px;
}
.change-languages {
  position: absolute;
  top: 15px;
  right: 20px;
}
.change-languages a {
  font-size: 16px;
  color: #DDDDDD;
  text-decoration: none;
  padding: 5px 10px;
  opacity: 0.5;
}
.change-languages a.active {
  opacity: 1;
}
select {
  width: 100% !important;
  padding: 5px !important;
  border-radius: 0px !important;
  border: none !important;
  border-bottom: solid 1px #686C6D !important;
}
option {
  color: black !important;
  select: focus;
  select-outline: none !important;
}
.margin-top20 {
  margin-top: 20px !important;
}
.margin-top15 {
  margin-top: 15% !important;
}
.text24 {
  font-size: 24px !important;
  color: #686C6D;
}
.float-traslate {
  position: absolute;
  left: 20px;
  top: 53%;
  z-index: 1000;
  color: #1AB394;
}
/*# sourceMappingURL=styles.css.map */
