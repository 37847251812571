import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationItemsService } from './service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-navigation-items',
    templateUrl: './template.html',
    styleUrls: ['./styles.sass'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, TranslateModule]
})
export class MyComponent implements OnInit {
  items: Array<any> = [
    {
      name: 'Owner Account',
      url: 'owner',
      codename: 'owner',
      active: false
    },
    {
      name: 'Property',
      url: 'property',
      codename: 'property',
      active: false
    },
    {
      name: 'Subscription',
      url: 'subscription',
      codename: 'subscription',
      active: false
    }
  ];
  menuVisible: boolean = false;
  @Output() menuVisibleOutput = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: NavigationItemsService
  ) { }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      this.items = [
        {
          name: 'Owner Account',
          url: 'owner',
          codename: 'owner',
          active: false
        },
        {
          name: 'Property',
          url: 'property',
          codename: 'property',
          active: false
        },
        {
          name: 'Subscription',
          url: 'subscription',
          codename: 'subscription',
          active: false
        }
      ];
      this.menuVisible = false;
      this.menuVisibleOutput.emit(this.menuVisible);
    });

    this.service.subscribe(options => {
      if (options && options.active) {
        this.menuVisible = true;
        this.items[
          this.items.map(i => i.codename).indexOf(options.codename)
        ].active = options.active;
      }
      else {
        this.menuVisible = false
      }
      this.menuVisibleOutput.emit(this.menuVisible);
    });

  }


  go(go: string) {
    const state = JSON.parse(localStorage.getItem('state') || '{}');
    state[go] ? this.router.navigate([this.items.find(i => i.codename === go).url]) : null;
  }

  canGo(go: string): boolean {
    const state = JSON.parse(localStorage.getItem('state') || '{}');
    return state[go] ? true : false;
  }
}
